body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label[id^="ponderation"] {
  font-size: 0.75em;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.left-bottom {
  left: 0;
  bottom: 0;
}

.z-10000 {
  z-index: 10000;
}

.other-links {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  margin-left: 1em;
}

.other-links li {
  padding: 0.25em;
}

.other-links li a {
  color: gray;
  text-decoration: none;
}

@media print {
  header.MuiAppBar-root {
    display: none !important;
  }

  div.MuiToolbar-root {
    display: none !important;
  }

  div[class^="RaLayout-appFrame-"] {
    margin-top: 0;
  }

  ul.other-links {
    display: none !important;
  }

  div.print_mandat-fonction,
  .no-break,
  li,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-inside: avoid !important;
    break-inside: avoid !important;
  }

  .page-break {
    page-break-after: always !important;
    page-break-before: always !important;
    break-before: page !important;
  }

  .MuiTabs-root {
    display: none !important;
  }

  *[class^="RaSimpleFormIterator-action-"] {
    display: none !important;
  }
}

.missing-translation p {
  color: #ef6c00 !important;
}
